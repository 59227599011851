<template lang="pug">
BasePane
  Accordion.mt-3(fix)
    Heading(expandableGap="12px" fix)
      template(#titleTrailing)
        .label {{ $t('closeOnEsc') }}
      template(#prependEnding)
        OmSwitch#close-on-esc(v-model="closeOnEsc")
    Heading(expandableGap="12px" :open="overlayOpen")
      template(#titleTrailing)
        .label {{ $t('closeOnOverlayClick') }}
      template(#prependEnding)
        OmSwitch#close-on-overlay-click(v-model="closeOnOverlayClick")
      template(#expandableContent)
        OmDeviceChooser(property="data.closeGestures.onOverlayClickDevice" :useNested="true")
    Dropdown#visibility-delay(
      :label="$t('visibilityDelay')"
      :items="visibilityOptions"
      v-model="visibilityDelay"
      layout="col"
      gridLayout="two-third"
    )
    OmColorInput(
      :label="$t('xColor')"
      property="globalStyle.closeButton.$device.color"
      typeOfComponent="global"
      layout="col"
      editMobile
      dsAllowed
    )
    FontFamily(v-model="closeFontFamily" manage layout="row")
    RangeInput(:label="$t('size')" v-model="size" :min="8" :max="70" :step="1")
    FontStyle(
      :label="$t('style')"
      :textWeight.sync="weight"
      :fontItalic.sync="italic"
      :textDecoration.sync="decoration"
    )
  hr.sidebar-spacing-y
  Accordion(fix)
    template(#title)
      span {{ $t('background') }}
    OmColorInput(
      :label="$t('backgroundColor')"
      property="globalStyle.closeButton.$device.background.color"
      typeOfComponent="global"
      layout="col"
      editMobile
      dsAllowed
    )
    RangeInput(:label="$t('borderRadius')" v-model="radius" :min="0" :max="50")
  hr.sidebar-spacing-y
  Accordion(fix)
    template(#title)
      span {{ $t('position') }}
    RangeInput(:label="$t('verticalAlignment')" v-model="vAlign" :min="-50" :max="50")
    RangeInput(:label="$t('horizontalAlignment')" v-model="hAlign" :min="-50" :max="50")
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import itemMixin from '@/editor/mixins/item';
  import BasePane from '@/editor/components/sidebar/panes/level2/BasePane.vue';
  import PaneLayout from '../../PaneLayout.vue';
  import FontFamily from '../../Controls/FontFamily.vue';
  import FontStyle from '../../FontStyle/FontStyle.vue';
  import Dropdown from '../../Dropdown/Dropdown.vue';

  export default {
    components: {
      Accordion,
      Heading,
      Align,
      RangeInput,
      BasePane,
      PaneLayout,
      FontFamily,
      FontStyle,
      Dropdown,
    },
    mixins: [itemMixin],
    data: () => ({
      canEditMobile: true,
      canUseNested: true,
    }),
    computed: {
      ...mapState(['mobilePreview']),
      ...mapGetters(['isSidebar']),
      closeOnEsc: {
        get() {
          return this.getValueOf('data.closeGestures.onEsc');
        },
        set(value) {
          this.setValueOf('data.closeGestures.onEsc', value);
        },
      },
      closeOnOverlayClick: {
        get() {
          return this.getValueOf('data.closeGestures.onOverlayClick');
        },
        set(value) {
          this.setValueOf('data.closeGestures.onOverlayClick', value);
        },
      },
      visibilityDelay: {
        get() {
          return this.getValueOf('globalStyle.closeButton.delay');
        },
        set(value) {
          this.setValueOf('globalStyle.closeButton.delay', value);
        },
      },
      closeFontFamily: {
        get() {
          return this.getNested('globalStyle.closeButton.$device.fontFamily');
        },
        set(value) {
          this.setNested('globalStyle.closeButton.$device.fontFamily', value);
        },
      },
      size: {
        get() {
          return Number(this.getNested('globalStyle.closeButton.$device.fontSize'));
        },
        set(value) {
          this.setNested('globalStyle.closeButton.$device.fontSize', value);
        },
      },
      weight: {
        get() {
          return this.getValueOf('globalStyle.closeButton.textWeight');
        },
        set(value) {
          this.setValueOf('globalStyle.closeButton.textWeight', value);
        },
      },
      italic: {
        get() {
          return this.getValueOf('globalStyle.closeButton.fontItalic');
        },
        set(value) {
          this.setValueOf('globalStyle.closeButton.fontItalic', value);
        },
      },
      decoration: {
        get() {
          return this.getValueOf('globalStyle.closeButton.textDecoration');
        },
        set(value) {
          this.setValueOf('globalStyle.closeButton.textDecoration', value);
        },
      },
      radius: {
        get() {
          return Number(this.getNested('globalStyle.closeButton.$device.borderRadius'));
        },
        set(value) {
          this.setNested('globalStyle.closeButton.$device.borderRadius', value);
        },
      },
      vAlign: {
        get() {
          return Number(this.getNested('globalStyle.closeButton.$device.top'));
        },
        set(value) {
          this.setNested('globalStyle.closeButton.$device.top', value);
        },
      },
      hAlign: {
        get() {
          return Number(this.getNested('globalStyle.closeButton.$device.right'));
        },
        set(value) {
          this.setNested('globalStyle.closeButton.$device.right', value);
        },
      },
      align: {
        get() {
          return this.getValueOf('data.align');
        },
        set(value) {
          this.setValueOf('data.align', value);
        },
      },
      overlayOpen() {
        return this.closeOnOverlayClick && !this.isSidebar;
      },
      visibilityOptions() {
        const isHu = this.$i18n.locale === 'hu';
        const postfix = isHu ? 'mp' : 'sec';
        const options = new Array(21)
          .fill('')
          .map((_, value) => ({ text: `${value} ${postfix}`, value }));
        return options;
      },
    },
    methods: {
      getNested(property) {
        if (this.mobilePreview) {
          return this.getValueOf(property.replace('$device', 'mobile'));
        }

        return this.getValueOf(property.replace('$device', 'desktop'));
      },
      setNested(property, value) {
        if (this.mobilePreview) {
          this.setValueOf(property.replace('$device', 'mobile'), value);
          return;
        }

        this.setValueOf(property.replace('$device', 'desktop'), value);
      },
    },
  };
</script>
